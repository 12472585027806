import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import wolf from '../../images/wolf.png'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const WolfPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Heart of a Wolf</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={wolf} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                Velvet Brown and Neal Corwell team up for a remarkably original
                recording inspired by the beauty of wild landscapes. Neal
                performs the euphonium solos for 2AM, Black Moon Rising, and
                Heart of a Wolf, and Velvet Brown performs the tuba solo,
                Aboriginal Voices. Neal and Velvet team up for the duo Ritual,
                and play all the euphonium and tuba tracks for The Furies, an
                8-part tuba-euphonium ensemble piece. Neal also performs all
                synthesizers and samplers heard throughout the CD, plus the
                guitar part for 2AM and the vocals effects for Ritual, 2AM,
                Heart of a Wolf and The Furies.
              </p>
              <p className='my-4'>
                <a
                  href='http://nealcorwell.com/page3/page19/page19.html'
                  target='_blank'
                  rel='noreferrer'
                >
                  Heart of a wolf is avalible here
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={wolf} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              Velvet Brown and Neal Corwell team up for a remarkably original
              recording inspired by the beauty of wild landscapes. Neal performs
              the euphonium solos for 2AM, Black Moon Rising, and Heart of a
              Wolf, and Velvet Brown performs the tuba solo, Aboriginal Voices.
              Neal and Velvet team up for the duo Ritual, and play all the
              euphonium and tuba tracks for The Furies, an 8-part tuba-euphonium
              ensemble piece. Neal also performs all synthesizers and samplers
              heard throughout the CD, plus the guitar part for 2AM and the
              vocals effects for Ritual, 2AM, Heart of a Wolf and The Furies.
            </p>
            <p className='my-4'>
              <a
                href='http://nealcorwell.com/page3/page19/page19.html'
                target='_blank'
                rel='noreferrer'
              >
                Heart of a wolf is avalible here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default WolfPage
